// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as IconSelect from "../../../../../styleguide/icons/IconSelect.res.js";
import * as Api_Country from "../../../../../api/countries/Api_Country.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FetchCountriesDashboardRequest from "../../../../../api/countries/requests/FetchCountriesDashboardRequest.res.js";
import * as CountriesDashboardOptionsScss from "./CountriesDashboardOptions.scss";

var css = CountriesDashboardOptionsScss;

var initialState = {
  status: "NotFixingCounts"
};

function CountriesDashboardOptions(props) {
  var updateCountries = props.updateCountries;
  var sortBy = props.sortBy;
  var page = "page=" + String(props.pageNum);
  var query = Belt_Option.getWithDefault(Belt_Option.map(props.query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "TotalCities" ? "&sort_by=countries.cities_count_active&sort_direction=" + FetchCountriesDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
      variant === "UpdatedAt" ? "&sort_by=countries.updated_at&sort_direction=" + FetchCountriesDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
          variant === "TotalStates" ? "&sort_by=countries.states_count_active&sort_direction=" + FetchCountriesDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
              variant === "Name" ? "&sort_by=countries.name&sort_direction=" + FetchCountriesDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
                  variant === "TotalLocations" ? "&sort_by=countries.locations_count_active&sort_direction=" + FetchCountriesDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : "&sort_by=countries.active&sort_direction=" + FetchCountriesDashboardRequest.Req.SortDirection.toString(sortBy.VAL)
                )
            )
        )
    );
  var url = "/api/v1/dashboard/countries.csv?" + (page + (query + sortBy$1));
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          switch (action) {
            case "FixCounts" :
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            var dispatch = param.dispatch;
                            $$Promise.wait(Api_Country.fixCounts(), (function (x) {
                                    if (x.TAG === "Ok") {
                                      dispatch("SucceedFixCounts");
                                      return updateCountries(x._0);
                                    }
                                    SentryLogger.error1({
                                          rootModule: "CountriesDashboardOptions",
                                          subModulePath: /* [] */0,
                                          value: "make",
                                          fullPath: "CountriesDashboardOptions.make"
                                        }, "CountriesDashboard::FixCounts::Error", [
                                          "Error",
                                          x._0
                                        ]);
                                    dispatch("FailFixCounts");
                                  }));
                          })
                      };
            case "SucceedFixCounts" :
                return {
                        TAG: "Update",
                        _0: {
                          status: "NotFixingCounts"
                        }
                      };
            case "FailFixCounts" :
                return {
                        TAG: "Update",
                        _0: {
                          status: "FailedFixingCounts"
                        }
                      };
            
          }
        }));
  var dispatch = match[1];
  return JsxRuntime.jsxs(Popover.make, {
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "SM",
                            color: "Primary",
                            className: css.ctaButton,
                            children: "Options",
                            iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                      size: "LG",
                                      color: "White"
                                    }))
                          })
                    }),
                JsxRuntime.jsxs(Popover.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "RightEdge"
                      },
                      className: css.popoverBody,
                      children: [
                        JsxRuntime.jsxs(Link.make, {
                              href: url,
                              className: css.popoverBodyLink,
                              children: [
                                "Download CSV",
                                JsxRuntime.jsx(IconSelect.make, {
                                      size: "MD",
                                      color: "LightestGray",
                                      className: css.popoverBodyLinkIcon
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs("a", {
                              children: [
                                "Update Counts",
                                JsxRuntime.jsx(IconSelect.make, {
                                      size: "MD",
                                      color: "LightestGray",
                                      className: css.popoverBodyLinkIcon
                                    })
                              ],
                              className: css.popoverBodyLink,
                              href: "#",
                              onClick: (function (param) {
                                  dispatch("FixCounts");
                                })
                            })
                      ]
                    })
              ]
            });
}

var make = CountriesDashboardOptions;

export {
  css ,
  initialState ,
  make ,
}
/* css Not a pure module */
